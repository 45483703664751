import React, { useState } from 'react';
import { Box, TextField, Card, CardContent, Typography } from '@mui/material';

const TakingNote = () => {
  const [note, setNote] = useState('');

  return (
    <Card className="my-8 shadow-lg rounded-lg">
      <CardContent>
        <Typography variant="h5" className="text-center mb-4 font-semibold">Notes</Typography>
        <Box>
          <TextField
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Take a note..."
            className="rounded-lg"
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default TakingNote;