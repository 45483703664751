import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, Button, Card, CardContent, ToggleButton, ToggleButtonGroup } from '@mui/material';

const CountdownTimer = () => {
  const [mode, setMode] = useState('session');
  const [minutes, setMinutes] = useState(25);
  const [seconds, setSeconds] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  // Request notification permissions when component mounts
  useEffect(() => {
    if (Notification.permission !== 'granted' && Notification.permission !== 'denied') {
      Notification.requestPermission().then(permission => {
        if (permission !== 'granted') {
          alert('You have not enabled notifications. Please enable them in your browser settings to receive notifications.');
        }
      });
    }
  }, []);

  // Function to show notification
  const showNotification = useCallback(() => {
    if (Notification.permission === 'granted') {
      const notification = new Notification('Pomodoro Complete!', {
        body: `It's time to take a break.`,
        icon: '../../public/bell-solid.svg'
      });
      notification.onclick = () => window.focus();
    } else {
      alert('Pomodoro Complete! It\'s time to take a break.');
    }
  }, []);

  // Timer logic
  useEffect(() => {
    let timerInterval = null;
    if (isRunning) {
      timerInterval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          if (minutes === 0) {
            clearInterval(timerInterval);
            setIsRunning(false);
            showNotification();
          } else {
            setMinutes(minutes - 1);
            setSeconds(59);
          }
        }
      }, 1000);
    } else if (!isRunning && seconds !== 0) {
      clearInterval(timerInterval);
    }
    return () => clearInterval(timerInterval);
  }, [isRunning, minutes, seconds, showNotification]);

  const handleStart = () => setIsRunning(true);
  const handleStop = () => setIsRunning(false);
  const handleReset = () => {
    setIsRunning(false);
    setMinutes(25);
    setSeconds(0);
  };

  const handleModeChange = (event, newMode) => {
    setMode(newMode);

    if (newMode === 'session') {
      setMinutes(25);
      setSeconds(0);
    } else if (newMode === 'shortBreak') {
      setMinutes(5);
      setSeconds(0);
    } else if (newMode === 'longBreak') {
      setMinutes(15);
      setSeconds(0);
    }
  };

  return (
    <Card className="my-8">
      <CardContent className="text-center">
        <ToggleButtonGroup value={mode} exclusive onChange={handleModeChange} aria-label="timer mode">
          <ToggleButton value="session">Pomodoro</ToggleButton>
          <ToggleButton value="shortBreak">Short Break</ToggleButton>
          <ToggleButton value="longBreak">Long Break</ToggleButton>
        </ToggleButtonGroup>

        <Box display="flex" justifyContent="center" alignItems="center" mt={2} mb={4}>
          <Typography variant="h1">
            {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
          </Typography>
        </Box>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button variant="contained" onClick={handleStart} sx={{ m: 1 }} color="primary">
            Start
          </Button>
          <Button variant="contained" onClick={handleStop} sx={{ m: 1 }} color="secondary">
            Stop
          </Button>
          <Button variant="contained" onClick={handleReset} sx={{ m: 1, backgroundColor: '#9e9e9e', color: 'white' }}>
            Reset
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CountdownTimer;