import React, { useState, useEffect } from 'react';
import { Box, TextField, List, ListItem, ListItemText, IconButton, Typography, Card, CardContent, Checkbox } from '@mui/material';
import { Add as AddIcon, Delete as DeleteIcon, CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from '@mui/icons-material';

const TodoList = () => {
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState('');

  // Load tasks from localStorage when the component mounts
  useEffect(() => {
    const storedTasks = localStorage.getItem('tasks');
    if (storedTasks) {
      setTasks(JSON.parse(storedTasks));
    }
  }, []);

  // Save tasks to localStorage whenever tasks change
  useEffect(() => {
    localStorage.setItem('tasks', JSON.stringify(tasks));
  }, [tasks]);

  const addTask = () => {
    if (task.trim()) {
      const newTasks = [...tasks, { text: task, completed: false }];
      setTasks(newTasks);
      setTask('');
    }
  };

  const handleInputChange = (event) => {
    setTask(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addTask();
    }
  };

  const toggleTaskCompletion = (index) => {
    const newTasks = tasks.map((task, i) => {
      if (i === index) {
        return { ...task, completed: !task.completed };
      }
      return task;
    });
    setTasks(newTasks);
  };

  const removeTask = (index) => {
    const newTasks = tasks.filter((_, i) => i !== index);
    setTasks(newTasks);
  };

  return (
    <Card className="my-8 shadow-lg rounded-lg">
      <CardContent>
        <Typography variant="h5" className="mb-4 text-center font-semibold">Tasks</Typography>
        <Box className="flex mb-4 justify-center">
          <TextField
            variant="outlined"
            fullWidth
            value={task}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Add a task..."
            className="mr-2"
            InputProps={{ 
              endAdornment: (
                <IconButton 
                  onClick={addTask} 
                  edge="end" 
                  color="primary"
                >
                  <AddIcon />
                </IconButton>
              )
            }}
          />
        </Box>
        <List>
          {tasks.map((task, index) => (
            <ListItem key={index} className="bg-gray-50 border mb-1 rounded-lg flex items-center">
              <Checkbox
                edge="start"
                checked={task.completed}
                onChange={() => toggleTaskCompletion(index)}
                icon={<CheckBoxOutlineBlankIcon />}
                checkedIcon={<CheckBoxIcon />}
              />
              <ListItemText primary={task.text} style={{ textDecoration: task.completed ? 'line-through' : 'none' }} />
              <IconButton edge="end" onClick={() => removeTask(index)}>
                <DeleteIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export default TodoList;