// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#5c6bc0', // Indigo 500
    },
    secondary: {
      main: '#ff7043', // Deep Orange 400
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Arial', 'sans-serif'].join(','),
  },
});

export default theme;