import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPaperPlane, FaEye, FaEyeSlash } from 'react-icons/fa'; // Importing icons
import '../styles/Chat.css';

const Chat = () => {
    const [apiKey, setApiKey] = useState('');
    const [displayedApiKey, setDisplayedApiKey] = useState('********'); // Displayed value, initially masked
    const [showApiKey, setShowApiKey] = useState(false); // Toggle state for showing API key
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState('');
    const [error, setError] = useState('');
    const [mode, setMode] = useState('chat');
    const [imagePrompt, setImagePrompt] = useState('');
    const [generatedImages, setGeneratedImages] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state

    const getLocalStorageData = (key, defaultValue) => {
        const storedData = localStorage.getItem(key);
        if (storedData) {
            return JSON.parse(storedData);
        }
        return defaultValue;
    };

    useEffect(() => {
        const storedApiKey = localStorage.getItem('openai_api_key');
        if (storedApiKey) {
            setApiKey(storedApiKey);
            setDisplayedApiKey('********'); // Display masked API key
        }

        const storedMessages = getLocalStorageData('chat_messages', []);
        setMessages(storedMessages);

        const storedImages = getLocalStorageData('generated_images', []);
        setGeneratedImages(storedImages);
    }, []);

    useEffect(() => {
        localStorage.setItem('chat_messages', JSON.stringify(messages));
    }, [messages]);

    useEffect(() => {
        localStorage.setItem('generated_images', JSON.stringify(generatedImages));
    }, [generatedImages]);

    const handleApiKeyChange = (e) => {
        const newApiKey = e.target.value;
        setApiKey(newApiKey);
        setDisplayedApiKey(newApiKey);
        setShowApiKey(true); // Show API key while typing
    };

    const handleApiKeyBlur = () => {
        if (apiKey) {
            setDisplayedApiKey('********');
            setShowApiKey(false);
        }
        localStorage.setItem('openai_api_key', apiKey);
    };

    const handleApiKeyClick = () => {
        setShowApiKey(!showApiKey); // Toggle showing the API key
        if (showApiKey) {
            setDisplayedApiKey('********');
        } else {
            setDisplayedApiKey(apiKey);
        }
    };

    const handleInputChange = (e) => {
        setInputMessage(e.target.value);
    };

    const handleImagePromptChange = (e) => {
        setImagePrompt(e.target.value);
    };

    const handleSendMessage = async () => {
        if (!apiKey) {
            setError('Please enter a valid API key.');
            return;
        }

        const userMessage = { role: 'user', content: inputMessage };
        const updatedMessages = [...messages, userMessage];

        setMessages(updatedMessages);
        setInputMessage('');

        try {
            const response = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: 'gpt-4o-mini',
                    messages: [{ role: 'system', content: 'You are a helpful assistant.' }, ...updatedMessages],
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${apiKey}`,
                    },
                }
            );

            const aiMessage = response.data.choices[0].message;
            const newMessages = [...updatedMessages, { role: 'assistant', content: aiMessage.content }];
            setMessages(newMessages);
            setError('');
        } catch (err) {
            console.log('Axios error:', err);
            setError('Failed to fetch response. Please check your API key and try again.');
        }
    };

    const handleGenerateImage = async () => {
        if (!apiKey) {
            setError('Please enter a valid API key.');
            return;
        }

        setLoading(true); // Set loading to true before starting the request
        try {
            const response = await axios.post(
                'https://api.openai.com/v1/images/generations',
                {
                    model: 'dall-e-3',
                    prompt: imagePrompt,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${apiKey}`,
                    },
                }
            );

            const newImages = response.data.data;
            setGeneratedImages((prevImages) => [...prevImages, ...newImages]);
            setError('');
        } catch (err) {
            console.log('Axios error:', err);
            setError('Failed to generate image. Please check your API key and try again.');
        } finally {
            setLoading(false); // Reset loading to false after the request completes
        }
    };

    const handleClearStorage = () => {
        localStorage.removeItem('chat_messages');
        localStorage.removeItem('generated_images');
        setMessages([]);
        setGeneratedImages([]);
    };

    return (
        <div className="min-h-screen bg-gray-100 flex flex-col items-center p-4">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-2xl">
                <div className="mb-4">
                    <h3 className="text-xl font-semibold mb-2">Enter your OpenAI API Key</h3>
                    <div className="relative">
                        <input
                            type="text"
                            value={displayedApiKey}
                            onChange={handleApiKeyChange}
                            onBlur={handleApiKeyBlur} // Handle blurring the input field
                            placeholder="API Key"
                            className="w-full p-2 border border-gray-300 rounded-md pr-10"
                        />
                        <button
                            onClick={handleApiKeyClick}
                            className="absolute right-2 top-1/2 transform -translate-y-1/2 p-2 bg-gray-200 border border-gray-300 rounded-md flex justify-center items-center"
                        >
                            {showApiKey ? <FaEyeSlash /> : <FaEye />}
                        </button>
                    </div>
                </div>
                <div className="flex mb-4 space-x-2">
                    <button
                        onClick={() => setMode('chat')}
                        className={`flex-1 p-2 text-lg font-semibold ${mode === 'chat' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                        Chat
                    </button>
                    <button
                        onClick={() => setMode('image')}
                        className={`flex-1 p-2 text-lg font-semibold ${mode === 'image' ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
                    >
                        Generate Image
                    </button>
                </div>
                {mode === 'chat' && (
                    <div className="chat-section">
                        <div className="flex justify-between items-center mb-2">
                            <h3 className="text-xl font-semibold">Chat with AI</h3>
                            <button
                                onClick={handleClearStorage}
                                className="p-2 text-sm bg-red-500 text-white rounded-md"
                            >
                                Clear Conversation
                            </button>
                        </div>
                        <div className="messages mb-4 p-4 border border-gray-300 rounded-md bg-gray-50 max-h-96 overflow-y-auto">
                            {messages.map((msg, index) => (
                                <div
                                    key={index}
                                    className={`message mb-2 flex items-start ${msg.role === 'user' ? 'justify-end' : 'justify-start'}`}
                                >
                                    <div
                                        className={`inline-block p-2 rounded-md ${msg.role === 'user' ? 'bg-blue-200 text-right' : 'bg-green-200 text-left'}`}
                                    >
                                        {msg.content}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="input-section flex items-center">
                            <input
                                type="text"
                                value={inputMessage}
                                onChange={handleInputChange}
                                placeholder="Type a message"
                                className="flex-1 p-2 border border-gray-300 rounded-md"
                            />
                            <button
                                onClick={handleSendMessage}
                                className="ml-2 p-2 bg-blue-500 text-white rounded-md flex items-center justify-center"
                            >
                                <FaPaperPlane className="text-xl" /> {/* Send Icon */}
                            </button>
                        </div>
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                    </div>
                )}
                {mode === 'image' && (
                    <div className="image-section">
                        <h3 className="text-xl font-semibold mb-2">Generate Image with AI</h3>
                        <div className="input-section flex mb-4">
                            <input
                                type="text"
                                value={imagePrompt}
                                onChange={handleImagePromptChange}
                                placeholder="Enter image prompt"
                                className="flex-1 p-2 border border-gray-300 rounded-md"
                            />
                            <button
                                onClick={handleGenerateImage}
                                className="ml-2 p-2 bg-blue-500 text-white rounded-md flex items-center justify-center"
                            >
                                <FaPaperPlane className="text-xl" /> {/* Send Icon */}
                            </button>
                        </div>
                        {loading && <div className="text-center mb-4">Generating image...</div>}
                        <div className="generated-images grid grid-cols-2 gap-4">
                            {generatedImages.map((image, index) => (
                                <div key={index} className="flex justify-center items-center">
                                    <img
                                        src={image.url}
                                        alt={`Generated ${index}`}
                                        className="w-full h-auto rounded-md shadow-md"
                                    />
                                </div>
                            ))}
                        </div>
                        {error && <p className="text-red-500 mt-2">{error}</p>}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Chat;